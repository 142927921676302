<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {
    this.$store.replaceState(
      Object.assign(
        {},
        this.$store.state,
        JSON.parse(localStorage.getItem("stateInfo"))
      )
    );
  },
};
</script>

<style lang="less">
body{
  margin: 0;
  padding: 0;
}
</style>
