import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from '@/store'
import axios from '@/util/axios'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import SlideVerify from 'vue-monoplasty-slide-verify';
import moment from 'moment' //导入文件
import 'moment/locale/zh-cn'
import '@/assets/css/index.css'

Vue.prototype.$moment = moment; //赋值使用
Vue.prototype.$http = axios
Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(SlideVerify);


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')