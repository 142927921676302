import _ from 'axios'
// import { stringify } from 'qs'
import { Message } from 'element-ui'
import { codeMessage, CODE } from './config'


const axios = _.create({
	baseURL: process.env.VUE_APP_PROXY,
	headers: {
		'Content-Type': 'application/json;charset=UTF-8'
	},
	withCredentials:true
})

axios.interceptors.response.use(res => {
	const {
		data: {
			data,
			code,
			info = ''
		},
		status
	} = res
	if (status !== 200) {
		return [ `连接失败 code:${ status }` ]
	}

	if (code !== CODE.success) {
		if (info == '暂无弹幕') {
			return
		}
		Message.error(info)
		return [ info ]
	}

	return [ null, data, code, info ]
}, err => {
	const { response } = err

	if (!response) {
		return [ '未连接到服务器' ]
	}

	// 还是需要返回错误原信息,因为接口需要传递发送信息与接口的信息
	if (response && response.status) {
		return [ codeMessage(response.status) ]
	}
})

export default axios