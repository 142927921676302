import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  // 申明数据
  state: {
    // 申明数据  相当于vue实例选项中的data
    login_token: "",
    nickname: "",
    user_id: "",
    avatar: "",
    vip:""
  },
  mutations: {

    CHANGE_LOGIN_TOKEN(state, n) {
      // state.count ++
      state.login_token = n
    },
    CHANGE_NICK_NAME(state, n) {
      // state.count ++
      state.nickname = n
    },
    CHANGE_USER_ID(state, n) {
      // state.count ++
      state.user_id = n
    },
    CHANGE_AVATAR(state, n) {
      // state.count ++
      state.avatar = n
    },
    CHANGE_VIP(state, n) {
      state.vip = n

    },
  }
})