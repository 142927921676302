
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
 

const routes = [
  {
    path: '/',
    name: 'Home',
    component: resolve => require(['@/views/Home'], resolve),
    redirect:'/movie',
    children:[
     /*  {
        path: '/live_streaming',
        name: 'live_streaming',
        meta: { auth: true, menu: true, name: '直播', },
        component: resolve => require(['@/views/live_streaming'], resolve)
      }, */
      {
        path: '/movie',
        name: 'movie',
        meta: { auth: true, menu: true, name: '视频', },
        component: resolve => require(['@/views/movie'], resolve)
      },
     /*  {
        path: '/music',
        name: 'music',
        meta: { auth: true, menu: true, name: '音乐',},
        component: resolve => require(['@/views/music'], resolve)
      }, */
      {
        path: '/deposit',
        name: 'deposit',
        meta: { auth: true, menu: true, name: '充值中心',},
        component: resolve => require(['@/views/deposit'], resolve)
      },
      {
        path: '/about',
        name: 'about',
        meta: { auth: true, menu: true, name: '公司介绍',},
        component: resolve => require(['@/views/about'], resolve)
      },
      {
        path: '/conceal',
        name: 'conceal',
        meta: { auth: true, menu: true, name: '隐私政策',},
        component: resolve => require(['@/views/agreement/conceal'], resolve)
      },
      {
        path: '/user_text',
        name: 'user_text',
        meta: { auth: true, menu: true, name: '用户服务协议',},
        component: resolve => require(['@/views/agreement/user_text'], resolve)
      },
      {
        path: '/infringement',
        name: 'infringement',
        meta: { auth: true, menu: true, name: '侵权投诉指引',},
        component: resolve => require(['@/views/agreement/infringement'], resolve)
      },
      {
        path: '/children',
        name: 'children',
        meta: { auth: true, menu: true, name: '未成年人协议',},
        component: resolve => require(['@/views/agreement/children'], resolve)
      },
      {
        path: '/user_pay',
        name: 'user_pay',
        meta: { auth: true, menu: true, name: '用户支付协议',},
        component: resolve => require(['@/views/agreement/user_pay'], resolve)
      },
      {
        path: '/selfCensorship',
        name: 'selfCensorship',
        meta: { auth: true, menu: true, name: '自审制度',},
        component: resolve => require(['@/views/agreement/selfCensorship'], resolve)
      },
      {
        path: '/dispute',
        name: 'dispute',
        meta: { auth: true, menu: true, name: '纠纷处理',},
        component: resolve => require(['@/views/agreement/dispute'], resolve)
      },
      {
        path: '/live',
        name: 'live',
        meta: { auth: false,menu:false},
        component: resolve => require(['@/views/live'], resolve)
      },
      /* {
        path: '/register',
        name: 'register',
        meta: { auth: true, menu: true, name: '注册',},
        component: resolve => require(['@/views/agreement/register'], resolve)
      }, */
    ]
  },
  {
    path: '/',
    name: 'Home',
    component: resolve => require(['@/views/Home'], resolve),
    children:[
      {
        path: '/user_info',
        name: 'User_info',
        component: resolve => require(['@/views/user_info/index'], resolve)
      },
    ]
  },
  {
    path: '/certification',
    name: 'Certification',
    component: resolve => require(['@/views/certification/index'], resolve)
  },
]



const router = new VueRouter({
  routes
})


export default router